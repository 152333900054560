<template>
  <a
    class="thumbnail-object thumbnail-border"
    :style="(dynamic_width) ? `width: ${dynamic_width}px;` : ''"
    :href="getArticleHref(reference.category_slug, reference.article_id, false)"
    @click.left.prevent="navigateToArticle(reference.category_slug, reference.article_id, false)"
  >
    <div
      class="thumbnail-bg-img"
      :style="(thumbnail != 'none') ? `background-image:url(${thumbnail})` : ''"
    >
      <v-card
        rounded="0"
        dark
      >
        <h2 class="top-gradient thumbnail-shadow">
          {{ title }}
        </h2>
        <v-spacer></v-spacer>
        <v-card-text class="bottom-gradient thumbnail-shadow">
          {{ publish_date }}
          <CountryFlag
            v-if="language"
            class="thumbnail-flag"
            size="normal"
            rounded
            :country="`${lang}`"
          />
          <v-spacer></v-spacer>
          {{ authors_formatted }}
        </v-card-text>
      </v-card>
    </div>
  </a>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import { Navigation } from '@/mixins/Navigation.mixin.js';

export default {
  components: {
      CountryFlag
  },

  props: [
    'title',
    'publish_date',
    'authors',
    'thumbnail',
    'reference',
    'language',
    'dynamic_width',
  ],

  computed: {
    lang() {
      let lang = this.language;
      if(lang == 'en') { lang = 'us' }
      return lang;
    },

    authors_formatted() {
      if(this.authors.length == 0) {
        return '';
      }

      if(this.authors.length > 1) {
        if(this.authors[0].fullname) {
          return `${this.authors[0].fullname} et al.`
        }
        else {
          return `${this.authors[0].first_name} ${this.authors[0].last_name} et al.`
        }
      }
      else {
        if(this.authors[0].fullname) {
          return `${this.authors[0].fullname}`
        }
        else {
          return `${this.authors[0].first_name} ${this.authors[0].last_name}`
        }
      }
    }
  },

  mixins: [Navigation, ],
}
</script>

<style lang="css" scoped>
.thumbnail-border {
  border-style: solid;
  border-color: white;
  border-width: 0 0 1px 0;
}
.thumbnail-object {
  position: relative;
  height: 220px;
  flex-shrink: 0;
  width: calc(var(--thumbnail-width-static) * 1px);
}
.thumbnail-bg-img {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.v-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, .5);
}
.v-card:hover {
  background: rgba(0, 0, 0, .33);
  cursor: pointer;
}
.v-card:hover > h2 {
  text-decoration: underline;
}
.top-gradient {
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
.bottom-gradient {
  padding-top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.thumbnail-shadow {
  text-shadow: 0px 2px 2px black;
}
.thumbnail-flag {
  transform: translate(-12px, 14px) scale(.33) !important;
}
h2 {
  padding: 16px;
  font-weight: 300;
  font-size: 14pt;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 8.5em; /* -webkit-line-clamp doesn't clip everything */
}
</style>
