export const ArticleDate = {
  computed: {
    date_today() {
      let string =''
      string += `${new Date().getFullYear()}/`;
      string += `${('0' + (new Date().getMonth() + 1)).slice(-2)}/` // leading 0 when month only 1 char
      string += `${('0' + (new Date().getDate())).slice(-2)}` // leading 0 when day of month only 1 char
      return string;
    },
  },

  methods: {
    getArticleDate(article) {
      if(article.published == 'public') {
        return this.formatDate(article.publish_date);
      }

      return this.date_today;
    },

    formatDate(date_string) {
      if(!date_string) {
        return this.date_today;
      }

      let date = date_string.substring(0, 10).replace(/[-]/g, '/');
      return date;
    },

    getPublishedDate(article) {
      if(!article || article.published == 'draft') {
        return '-';
      }

      return this.formatDate(article.publish_date);
    },

    getUpdatedDate(article) {
      if(!article) {
        return '-';
      }

      return this.formatDate(article.updated);
    }
  },
};
